import React, { useContext } from 'react'
import { Icon } from "components/lib"
import { useNavigate } from 'react-router-dom';
import { useAdmin } from 'contexts/admin';
import { ViewContext } from 'contexts/view';
import CreateLeadModal from './create_lead_modal';

const Sidebar = (props) => {
  const city = localStorage.getItem("city");
  const admin = useAdmin();
  const context = useContext(ViewContext);

  const handleCreateLeadModal = () => {
    context.modal.show({
      title: "Создать новый лид",
      children: <CreateLeadModal handleCreateLead={admin.handleCreateLead} />,
    })
  };

  return (
    <aside className="w-64 bg-white h-full fixed top-20 left-0 z-10">
      <div className="flex items-center gap-2 px-4 mt-6">
        <div className="bg-slate-200 p-4 rounded-full">
          <Icon image="settings" />
        </div>
        <div className="flex flex-col">
          <span className="font-bold">Talk&Call HR</span>
          <span className="font-extralight text-gray-400">Admin Panel</span>
        </div>
      </div>
      <nav className="mt-6 space-y-3 px-4">
        <NavItem icon="home" label="Дашборд" link="dashboard" />

        {/* <NavItem icon="users" label="Лиды" link="leads" /> */}
        {/* <NavItem icon="briefcase" label="Портфолио" link="portfolios" /> */}

        {/* <NavItem icon="settings" label="Настройки" link="settings" /> */}
        {city === "Все" && (
          <NavItem icon="pie-chart" label="Статистика" link="statistics" />
        )}
      </nav>

      <div className="p-4 mt-auto">
        <button
          onClick={() => handleCreateLeadModal()}
          className="w-full px-4 py-2 bg-indigo-400 text-white rounded-full hover:bg-indigo-500 transition"
        >
          Добавить лид
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;

function NavItem({ icon, label, link }) {
  const navigate = useNavigate()

  return (
    <button
      className="flex items-center gap-2 px-4 py-2 w-full hover:bg-gray-100 transition rounded-full text-black"
      onClick={() => navigate(`/admin/${link}`)}
    >
      <Icon image={icon} />
      <span className="text-lg">{label}</span>
    </button>
  );
};
